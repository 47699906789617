
  import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
  import { Action, State as StateClass, Getter } from 'vuex-class';
  import to from 'await-to-js';
  import axios from 'axios';
  // @ts-ignore
  import VueUploadMultipleImage from 'vue-upload-multiple-image';
  // @ts-ignore
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { State } from '@/models/State';
  import { DataContainerStatus } from '@/models/Common';
  import { bloqifyFirestore, bloqifyStorage } from '@/boot/firebase';
  import { convertUTCToLocalDate, convertLocalDateToUTC } from '@/filters/date';
  import { CurrentManager } from '@/models/manager/CurrentManager';
  import { Asset } from '@/models/assets/Asset';
  import FormInput, { FormIcons } from '@/components/common/form-elements/FormInput.vue';
  import FormSelect from '@/components/common/form-elements/FormSelect.vue';
  import FormInvalidMessage from '@/components/common/form-elements/FormInvalidMessage.vue';
  import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue';
  import FormEditor from '@/components/common/form-elements/FormEditor.vue';
  import singleDocumentQuery from '@/mixins/singleDocumentQuery';

  type FileNames = 'images' | 'floorPlanImages';

  @Component({
    components: {
      VueUploadMultipleImage,
      FormDatePicker,
      FormEditor,
      FormInput,
      FormSelect,
      ValidationObserver,
      ValidationProvider,
      FormInvalidMessage,
    },
    mixins: [
      singleDocumentQuery({
        ref: bloqifyFirestore.collection('assets'),
        stateSlice: 'boundAsset',
        idName: 'assetId',
        singleFetch: false,
      }),
    ],
  })
  export default class CreateAssets extends Vue {
    FormIcons = FormIcons;
    name: string = '';
    street: string = '';
    houseNumber: string = '';
    postalCode: string = '';
    city: string = '';
    country: string = '';
    investmentCase: any = '';
    propertyDetails: any = '';
    totalValueEuro: number | null = null;
    euroMin: number | null = null;
    sharePrice: number | null = null;
    emissionCost: number | null = null;
    rentalIncome: number | null = null;
    addedValue: number | null = null;
    premium: boolean = false;
    reservation: boolean = false;
    published: boolean = false;
    dividendsFormat: { contents: [string, number | null | string] }[] = [{ contents: ['', null] }];
    images: any[] = [];
    floorPlanImages: any[] = [];
    prospectus: any[] = [];
    brochure: any[] = [];
    legalDocument1: any = [];
    legalDocument2: any = [];
    location: { lat: number, lng: number } = { lat: 0, lng: 0 };
    mapOptions = {
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: true,
    };
    imagesLoading = {
      images: false,
      floorPlanImages: false,
    };
    startDateTime: Date | null = null;
    endDateTime: Date | null = null;
    returnsAfterEnd: number | null = null;
    fixedDividends: boolean | null = null;
    otherChanges: boolean = false;
    saveButtonTitle: string = '';
    isCreatingAssetOnBlockchain: boolean = false;
    isMinting: boolean = false;

    @Action createAsset!: Function;
    @Action createAssetOnBlockchain!: Function;
    @Action updateAsset!: Function;
    @Action handlePublishAssetById!: Function;
    @Action handleMintAssetTokensOnBlockchainById!: Function;
    @Action(addToastMessage) addToastMessage!: Function;

    @Getter getCurrentManager!: CurrentManager;

    @StateClass('boundAsset') asset!: State['boundAsset'];
    @StateClass('asset') operationalAsset!: State['asset'];
    @StateClass blockchain!: State['blockchain'];

    @Ref('form') readonly form!: InstanceType<typeof ValidationObserver>;

    @Watch('operationalAsset.error')
    onNewAssetError(newError?: Error): void {
      if (newError) {
        this.addToastMessage({
          text: newError.message,
          type: 'danger',
        });
      }
    }

    @Watch('operationalAsset.status')
    async onOperationalAssetStatusChange(newStatus: DataContainerStatus): Promise<void> {
      if ((this.operationalAsset?.operation === 'createAsset' || this.operationalAsset?.operation === 'updateAsset')
        && newStatus === DataContainerStatus.Success) {
        // Redirect if we were in create page
        if (this.$route.fullPath !== `/create-modify-asset/${this.operationalAsset.payload.id}`) {
          this.$router.push(`/create-modify-asset/${this.operationalAsset.payload.id}`);
        }

        this.addToastMessage({
          text: 'Fund correctly saved.',
          type: 'success',
        });
        // Resetting form validation
        this.otherChanges = false;
        this.form.reset();
      } else if (this.operationalAsset?.operation === 'createAssetOnBlockchain') {
        if (newStatus === DataContainerStatus.Success) {
          this.addToastMessage({
            text: 'Fund successfully created on blockchain.',
            type: 'success',
          });
        }
        if (newStatus === DataContainerStatus.Processing) {
          this.isCreatingAssetOnBlockchain = true;
        } else {
          this.isCreatingAssetOnBlockchain = false;
        }
      } else if (this.operationalAsset?.operation === 'handleMintAssetTokensOnBlockchainById') {
        if (newStatus === DataContainerStatus.Processing) {
          this.isMinting = true;
        } else {
          this.isMinting = false;
        }
      }
    }

    @Watch('operationalAsset', { deep: true })
    onBlockchainAssetChanged(newBlockchainAssetChanged): void {
      if (newBlockchainAssetChanged?.operation === 'handlePublishAssetById' && newBlockchainAssetChanged?.status === 'success') {
        if (newBlockchainAssetChanged?.status === 'success') {
          this.addToastMessage({
            text: `The asset was ${this.published ? 'published' : 'unpublished'}!`,
            type: 'success',
          });
        }
      }
    }

    @Watch('asset')
    async onAssetStatusChange(newAsset: Asset): Promise<void> {
      if (newAsset) {
        const imgArrayNames = ['images', 'floorPlanImages'];
        const fileArrayNames = ['prospectus', 'brochure', 'legalDocuments'];
        const allArrayNames = [...imgArrayNames, ...fileArrayNames];

        const asset = newAsset;

        // Here we are setting the received data into the form fields
        Object.keys(asset).forEach((key): void => {
          if (!allArrayNames.some((arrayName): boolean => arrayName === key)) {
            // Assigining dynamically all the asset properties to the form
            if (key !== 'investmentCase' && key !== 'propertyDetails' && key !== 'dividendsFormat'
              && key !== 'startDateTime' && key !== 'endDateTime') {
              this[key] = asset[key];
            }
          } else {
            // Resetting file arrays
            this[key] = [];
          }
        });

        ['startDateTime', 'endDateTime'].forEach((dateKey): void => {
          // From unix format to Date format
          this[dateKey] = (asset[dateKey] && convertUTCToLocalDate(asset[dateKey])) || null;
        });

        // To avoid mutations at editing
        this.dividendsFormat = [
          ...asset.dividendsFormat.map(
            (contentObject): {
              contents: [string, number];
            } => ({ ...contentObject }),
          ),
        ];

        // Editor content
        this.investmentCase = asset.investmentCase;
        this.propertyDetails = asset.propertyDetails;

        // Images
        imgArrayNames.forEach(async (key): Promise<void> => {
          let imgLoadedCount = 0;

          await Promise.all(asset[key].map(async (img): Promise<void> => {
            // Loader ON
            this.imagesLoading[key] = true;

            const storageRef = bloqifyStorage.ref().child(img);
            const [getDownloadUrlError, fileUrl] = await to(storageRef.getDownloadURL());
            if (getDownloadUrlError) {
              this.addToastMessage({
                text: getDownloadUrlError.message || 'There was an error retrieving one of images.',
                type: 'danger',
              });
              throw getDownloadUrlError;
            }

            const [getMetadataError, metadata] = await to(storageRef.getMetadata());
            if (getMetadataError) {
              this.addToastMessage({
                text: getMetadataError.message || 'There was an error retrieving one of images.',
                type: 'danger',
              });
              throw getMetadataError;
            }

            const { contentType, name: fileName, fullPath: path } = metadata;

            const [getFileError, response] = await to(axios.get(
              fileUrl,
              {
                responseType: 'arraybuffer',
              },
            ));
            if (getFileError) {
              this.addToastMessage({
                text: getFileError.message || 'There was an error retrieving one of images.',
                type: 'danger',
              });
              throw getFileError;
            }

            const responseBlob = response!.data as Blob;
            const reader = new FileReader();

            reader.readAsDataURL(new Blob([responseBlob], { type: contentType }));
            reader.onload = (e) => {
              const length = this[key].length;
              // This object type is the one required by the component used for images
              this[key].push({
                name: fileName,
                path: reader.result,
                fullPath: path,
                highlight: length <= 1 ? 0 : 1,
                default: length <= 1 ? 0 : 1,
                file: new File([responseBlob], fileName, { type: contentType }),
              });
              this[key].sort((a, b) => asset[key].indexOf(a.fullPath) - asset[key].indexOf(b.fullPath));

              imgLoadedCount++;

              if (imgLoadedCount === asset[key].length) {
                // Loader OFF
                this.imagesLoading[key] = false;
              }
            };
          }));
        });

        // Files (pdfs)
        fileArrayNames.forEach(async (key): Promise<void> => {
          await Promise.all((asset[key] || []).map(async (pdf, index): Promise<void> => {
            const contentType = 'application/pdf';
            const storageRef = bloqifyStorage.ref().child(pdf);
            const [getError, fileUrl] = await to(storageRef.getDownloadURL());
            if (getError) {
              this.addToastMessage({
                text: getError.message || 'There was an error retrieving one of docs.',
                type: 'danger',
              });
              throw getError;
            }

            const [getMetadataError, metadata] = await to(storageRef.getMetadata());
            if (getMetadataError) {
              this.addToastMessage({
                text: getMetadataError.message || 'There was an error retrieving one of docs.',
                type: 'danger',
              });
              throw getMetadataError;
            }

            const { name: fileName } = metadata;

            const [getFileError, response] = await to(axios.get(
              fileUrl,
              {
                responseType: 'arraybuffer',
              },
            ));
            if (getFileError) {
              this.addToastMessage({
                text: getFileError.message || 'There was an error retrieving one of docs.',
                type: 'danger',
              });
              throw getFileError;
            }

            if (key === 'legalDocuments') {
              if (index === 0) {
                this.legalDocument1.push({ name: fileName, file: new File([response!.data], fileName, { type: contentType }) });
              }
              if (index === 1) {
                this.legalDocument2.push({ name: fileName, file: new File([response!.data], fileName, { type: contentType }) });
              }
            } else {
              this[key].push({ name: fileName, file: new File([response!.data], fileName, { type: contentType }) });
            }
          }));
        });
      }
    }

    @Watch('addressBuilt')
    async onAddressChange(newAddress: string, oldAddress: string): Promise<void> {
      if (this.city && this.city.length > 2) {
        // @ts-ignore
        const [mapsApiError, mapsApi] = await to(this.$gmapApiPromiseLazy());
        if (mapsApiError) {
          return;
        }
        // @ts-ignore
        const geocoder = new mapsApi.maps.Geocoder();
        geocoder.geocode({ address: newAddress }, (results, status): void => {
          if (status === 'OK') {
            const location = results[0].geometry.location;
            this.location = {
              lat: location.lat instanceof Function ? location.lat() : location.lat,
              lng: location.lng instanceof Function ? location.lng() : location.lng,
            };
          }
        });
      }
    }

    @Watch('premium') onPremiumChange(newPremium: boolean) {
      // reset dividend data
      this.dividendsFormat = [{ contents: ['', 0] }];
      this.returnsAfterEnd = null;
      this.fixedDividends = null;
    }

    get assetTokensMinted(): boolean {
      return this.asset! && this.asset.totalValueShares === this.asset.blockchainTokensMinted;
    }

    get loadingAsset(): boolean {
      return this.operationalAsset?.status === DataContainerStatus.Processing;
    }

    get URL(): Function {
      // @ts-ignore
      return window.URL || window.webkitURL;
    }

    get addressBuilt(): string {
      return `${this.street} ${this.houseNumber} ${this.city} ${this.country}`;
    }

    get assetId(): string | undefined {
      return this.$route.params.assetId;
    }

    get lastUpdate(): number | null {
      if (this.assetId && this.asset) {
        const timestamp = convertUTCToLocalDate(this.asset.updatedDateTime || this.asset.createdDateTime)!;
        return timestamp.getTime();
      }

      return null;
    }

    // Handle save button loading spinner
    get saveLoading(): boolean {
      if (!this.loadingAsset) {
        return false;
      }

      return (this.operationalAsset?.operation === 'updateAsset' || this.operationalAsset?.operation === 'createAsset');
    }

    get publishing(): boolean {
      return this.operationalAsset?.operation === 'handlePublishAssetById' && this.operationalAsset.status === DataContainerStatus.Processing;
    }

    /*
    * Checks if the user should be able to save the draft, that requires
    * the name to be defined
    * all errors present should only be due to them missing (i.e. in violation of being required)
    * if it is already published there can't be any invalid entries
    * */
    isAbleToSaveDraft(): boolean {
      if (this.name.length === 0) {
        this.saveButtonTitle = 'Enter a fund name to save draft';
        return false;
      }

      // Make reservation type asset publishable with only a name
      if (this.reservation) {
        return true;
      }

      if (!this.form) {
        return false;
      }

      if (this.published) {
        return this.form.flags.valid;
      }

      if (this.form.errors) {
        const isOnlyRequiredViolated = Object.values(this.form.fields).every(
          (field) => {
            if (field.failed) {
              return Object.keys(field.failedRules).every(((failedRuleKey) => failedRuleKey === 'required'));
            }
            return true;
          },
        );
        this.saveButtonTitle = isOnlyRequiredViolated ? '' : 'Invalid input';
        return isOnlyRequiredViolated;
      }
      return true;
    }

    inputFilter(newFile, oldFile, prevent): void {
      // Preventing a non-pdf file to be inserted
      if (newFile && !oldFile && !/\.(pdf)$/i.test(newFile.name)) {
        this.addToastMessage({
          text: 'Only pdf files allowed.',
          type: 'danger',
        });
        prevent();
      } else {
        this.otherChanges = true;
      }
    }

    removeFile(position: number, fileType: string) {
      this[fileType].splice(position, 1);
      this.otherChanges = true;
    }

    changeDividend(event, type, index): void {
      const targetValue = event;
      const year = this.dividendsFormat[index].contents[0];
      const dividends = this.dividendsFormat[index].contents[1];
      if (type === 'year') {
        this.dividendsFormat[index].contents = [targetValue, dividends];
      } else {
        this.dividendsFormat[index].contents = [year, targetValue];
      }
    }

    removeDividendsRow(row: number): void {
      this.dividendsFormat.splice(row, 1);
      this.otherChanges = true;
    }

    addDividendsRow(): void {
      this.dividendsFormat.push({ contents: ['', 0] });
    }

    markPrimary(index: number, fileList: any[], type: FileNames): void {
      this[type] = fileList;
      this.otherChanges = true;
    }

    uploadOrEditImage(formData: FormData, index: number, fileList: any[], type: FileNames): void {
      const file = formData.get('file') as File;
      if (file) {
        // Limiting size by 10MB
        if (file.size < 10000000) {
          this[type] = fileList;
          this[type][index].file = formData.get('file');
          this.otherChanges = true;
        } else {
          fileList.pop();
        }
      }
    }

    beforeRemove(index: number, removeFn: Function, fileList: any[], type: FileNames): void {
      removeFn();
      this[type].splice(index, 1);
      this.otherChanges = true;
    }

    async submitAsset(): Promise<void> {
      await this.form.validate();
      if (this.isAbleToSaveDraft()) {
        const formattedDividendsFormat = this.dividendsFormat.map((div) => ({
          contents: [
            div.contents[0],
            Number(div.contents[1]),
          ],
        }));

        const formAsset = {
          ...this.assetId && { id: this.assetId },
          name: this.name.trim(),
          street: this.street.trim(),
          houseNumber: this.houseNumber.trim(),
          postalCode: this.postalCode.trim(),
          city: this.city.trim(),
          country: this.country.trim(),
          dividendsFormat: formattedDividendsFormat,
          investmentCase: this.investmentCase,
          propertyDetails: this.propertyDetails,
          totalValueEuro: Number(this.totalValueEuro),
          euroMin: Number(this.euroMin),
          sharePrice: Number(this.sharePrice),
          emissionCost: Number(this.emissionCost),
          rentalIncome: Number(this.rentalIncome),
          addedValue: Number(this.addedValue),
          premium: this.premium,
          reservation: this.reservation,
          published: this.published,
          images: this.images.map((img): any => img.file),
          floorPlanImages: this.floorPlanImages.map((img): any => img.file),
          prospectus: this.prospectus.map((prospectus): any => prospectus.file),
          brochure: this.brochure.map((prospectus): any => prospectus.file),
          legalDocuments: [this.legalDocument1[0]?.file, this.legalDocument2[0]?.file],
          startDateTime: this.startDateTime && convertLocalDateToUTC(this.startDateTime, true)?.getTime(),
          endDateTime: this.endDateTime && convertLocalDateToUTC(this.endDateTime, true)?.getTime(),
          returnsAfterEnd: Number(this.returnsAfterEnd),
          ...(this.fixedDividends !== null && { fixedDividends: this.fixedDividends }),
        };

        if (!this.assetId) {
          this.createAsset({
            asset: formAsset,
            connectedToBlockchain: this.blockchain!.connectedToBlockchain,
            managerId: this.getCurrentManager.uid,
          });
        } else {
          this.updateAsset({ asset: formAsset });
        }
      }
    }

    async publish(): Promise<void> {
      if (!this.assetId) {
        this.addToastMessage({
          text: 'Please, save the asset first.',
          type: 'danger',
        });

        // Setting back to false with some delay due to the visual effect not being applied if insta change twice
        setTimeout(() => {
          this.published = false;
        }, 200);
        return;
      }

      this.handlePublishAssetById({ assetId: this.assetId, published: this.published });
    }

    mintAssetTokensOnBlockchain(): void {
      if (!this.assetId) {
        this.addToastMessage({
          text: 'Please, save the asset first.',
          type: 'danger',
        });
        return;
      }
      this.handleMintAssetTokensOnBlockchainById({
        assetId: this.assetId,
        managerId: this.getCurrentManager.uid,
      });
    }

    handleCreateAssetOnBlockchain(): void {
      if (!this.assetId) {
        this.addToastMessage({
          text: 'Please, save the asset first.',
          type: 'danger',
        });
        return;
      }
      this.createAssetOnBlockchain({
        assetCustomId: this.asset!.customId,
        connectedToBlockchain: this.blockchain!.connectedToBlockchain,
        managerId: this.getCurrentManager.uid,
      });
    }
  }
